import { default as abuseReports7B2qcs2goUMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93HJIx2PiaToMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexLypk6ZQeXDMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/index.vue?macro=true";
import { default as moderationAu8xm5oR33Meta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/moderation.vue?macro=true";
import { default as pendingBans7nE1G5yUD4Meta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/pendingBans.vue?macro=true";
import { default as profiles86Z5O0b0XrMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/profiles.vue?macro=true";
import { default as expensescSou0eJqPdMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexb8uQ7uL13vMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewrCveWuTY8vMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingsJwp43rJAdMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingj25rEip6StMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/translations/missing.vue?macro=true";
import { default as usersCjm7z9Qze9Meta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/users.vue?macro=true";
import { default as apiNb5WgSf86VMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/api.vue?macro=true";
import { default as _91slug_93zLgpsv56vVMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/blog/[slug].vue?macro=true";
import { default as indexWW7jMIZc99Meta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_936TKPmWISuHMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_938joEbkUQdlMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminyIht1uwiJuMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/census/admin.vue?macro=true";
import { default as indexr7uogNprnNMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/census/index.vue?macro=true";
import { default as contactMc7g1xT9p3Meta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/contact.vue?macro=true";
import { default as designndaQA66UH5Meta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/design.vue?macro=true";
import { default as englishwrhcTW7wqAMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/english.vue?macro=true";
import { default as faqTdWwO4eTkzMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/faq.vue?macro=true";
import { default as inclusive08b2eLZEqWMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/inclusive.vue?macro=true";
import { default as indexgUlTZVWrpVMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/index.vue?macro=true";
import { default as licensejcM6ZYQ27yMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/license.vue?macro=true";
import { default as academicAcQflpRKYeMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/links/academic.vue?macro=true";
import { default as indexQdfaMX5silMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/links/index.vue?macro=true";
import { default as mediajHQbHeKBLqMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/links/media.vue?macro=true";
import { default as translinguisticsuTddJnRis1Meta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/links/translinguistics.vue?macro=true";
import { default as zine8Kvbz0quGxMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/links/zine.vue?macro=true";
import { default as namesQzIME5Xh1hMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/names.vue?macro=true";
import { default as index5KdMCDmkXPMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/nouns/index.vue?macro=true";
import { default as templatesS2mTC8UxbhMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/nouns/templates.vue?macro=true";
import { default as people5eUoSjPjeIMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/people.vue?macro=true";
import { default as privacy2e1qL1Cj5vMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/privacy.vue?macro=true";
import { default as _91username_935vovH5blJ8Meta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93p7jzX0MiWpMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/profile/card-[username].vue?macro=true";
import { default as editor2iM7hLdJcqMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/profile/editor.vue?macro=true";
import { default as anyXq2D0LZD63Meta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/pronouns/any.vue?macro=true";
import { default as askiXYzDxri7uMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/pronouns/ask.vue?macro=true";
import { default as avoidingOD0GbrmH5OMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/pronouns/avoiding.vue?macro=true";
import { default as indexsFcGlC1u1ZMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/pronouns/index.vue?macro=true";
import { default as mirrorjhlxG2FKCsMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/pronouns/mirror.vue?macro=true";
import { default as pronounYHW43kd3YpMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/pronouns/pronoun.vue?macro=true";
import { default as sources8Ig2gdESPzMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/sources.vue?macro=true";
import { default as team0f6d5k7AWeMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/team.vue?macro=true";
import { default as terminologyXkV9LiKRwAMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/terminology.vue?macro=true";
import { default as termsDK1EY0lSlYMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/terms.vue?macro=true";
import { default as userEmeUaQHL5vMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/user.vue?macro=true";
import { default as workshopsI14ONZPNsIMeta } from "/home/admin/www/en.pronouns.page/release/20241116154530/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReports7B2qcs2goUMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBans7nE1G5yUD4Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiNb5WgSf86VMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93zLgpsv56vVMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexWW7jMIZc99Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_936TKPmWISuHMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_938joEbkUQdlMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminyIht1uwiJuMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexr7uogNprnNMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactMc7g1xT9p3Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishwrhcTW7wqAMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqTdWwO4eTkzMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusive08b2eLZEqWMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicAcQflpRKYeMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexQdfaMX5silMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediajHQbHeKBLqMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsuTddJnRis1Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zine8Kvbz0quGxMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesQzIME5Xh1hMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: index5KdMCDmkXPMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesS2mTC8UxbhMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: people5eUoSjPjeIMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacy2e1qL1Cj5vMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_935vovH5blJ8Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93p7jzX0MiWpMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editor2iM7hLdJcqMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyXq2D0LZD63Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askiXYzDxri7uMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingOD0GbrmH5OMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexsFcGlC1u1ZMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorjhlxG2FKCsMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounYHW43kd3YpMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sources8Ig2gdESPzMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: team0f6d5k7AWeMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyXkV9LiKRwAMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsDK1EY0lSlYMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userEmeUaQHL5vMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsI14ONZPNsIMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241116154530/pages/workshops.vue")
  }
]